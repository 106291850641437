<template lang="html">
  <div class="search search--container">
    <div class="search--wrap">
      <div class="search__title js-t-open">
        <form
          class="search__title__form"
          action="index.html"
          method="post"
          @submit.prevent=""
        >
          <input
            class="search__input"
            type="text"
            name=""
            value=""
            :placeholder="$t('home.home_search_placeholder')"
            :value="keyword"
            @input="searchingKeydown"
            autocomplete="off"
          />
          <button
            type="button"
            class="search__button icon-search"
            name="button"
          ></button>
        </form>
      </div>
      <transition name="fade">
        <div class="search__cat filter" v-show="keyword">
          <div
            class="search__filter"
            v-for="tax in searchFilterTaxTermsCount"
            :key="tax.id"
          >
            <p class="">{{ lang == "en" ? tax.title_en : tax.title_tc }}</p>
            <ul class="search__filter__list">
              <li class="search__filter__term" v-for="item in tax.term">
                <button
                  v-if="item.record_count > 0"
                  type="button"
                  name="button"
                  :data-count="item.record_count"
                  @click="getRecordByTerm(tax, item)"
                >
                  <span
                    class="colorHex"
                    v-if="item.color_hex"
                    v-bind:style="{ 'background-color': item.color_hex }"
                  >
                  </span>
                  <span
                    >{{ lang == "en" ? item.title_en : item.title_tc }}
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </transition>

      <div class="search__cat category" v-show="!keyword">
        <div class="category__list main-category">
          <div
            class="category__item "
            v-for="(taxonomy, index) in taxonomyList"
            :key="taxonomy.slug"
            :class="{
              shy: selectedTaxonomy.slug !== taxonomy.slug && searchState
            }"
          >
            <button
              @click="catFilter(taxonomy.slug, taxonomy.title_en)"
              type="button"
              name="button"
            >
              <span>{{
                lang == "en"
                  ? taxonomy.title_en
                  : taxonomy.title_tc | capitalize
              }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="search__cat category" v-show="keyword">
        <div class="category__list main-category">
          <div
            class="category__item "
            v-for="(taxonomy, index) in taxonomyList"
            :key="taxonomy.slug"
            :class="{
              shy: selectedTaxonomy.slug !== taxonomy.slug && searchState
            }"
          >
            <button
              @click="catkeyWordFilter(taxonomy)"
              type="button"
              name="button"
            >
              <span>{{
                lang == "en"
                  ? taxonomy.title_en
                  : taxonomy.title_tc | capitalize
              }}</span>
            </button>
          </div>
        </div>
      </div>

      <!-- start of sub category -->
      <div
        class="category category__sub"
        v-show="keyword == '' && searchState != false"
      >
        <div class="category__list">
          <div
            class="category__item"
            v-for="(term, index) in termList.term"
            :class="getTermClass(term.slug)"
            v-show="getTermRecordCount(term.id, selectedTerm) > 0"
          >
            <button
              type="button"
              name="button"
              :data-count="getTermRecordCount(term.id, selectedTerm)"
              @click="
                termFilter(term.id, term.slug, term.title_en, term.record_count)
              "
            >
              <span
                class="colorHex"
                v-if="term.color_hex"
                v-bind:style="{ 'background-color': term.color_hex }"
              >
              </span>
              <span>
                {{ lang == "en" ? term.title_en : term.title_tc | capitalize }}
              </span>
            </button>
          </div>
        </div>
      </div>
      <!-- end of sub category -->

      <div v-if="searchFilterTerms">
        <ul class="search__filter__list selected">
          <li class="search__filter__term" v-for="term in searchFilterTerms">
            <button type="button" name="button" @click="removeTerm(term)">
              <span>{{ lang == "en" ? term.title_en : term.title_tc }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";

export default {
  name: "SearchFilter",
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),

      selectedTaxonomy: { slug: String, title: String },
      selectedTerm: [],
      taxonomyList: [],
      termList: Object,
      keyword: "",
      searchRecordList: [],
      searchTimeout: null,
      searchLoading: false,
      searchFilterTerms: [],
      searchFilterTaxList: [],
      searchFilterTaxTermsCount: [],
      apiCall: null
    };
  },

  props: {
    searchState: Boolean
  },

  beforeMount() {
    this.apiData();
  },

  watch: {
    termList() {}
  },

  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },

  computed: {
    ...mapGetters("common", ["getRecordwithTerm"])
  },

  methods: {
    searchingKeydown(e) {
      this.keyword = e.target.value;
      if (this.keyword !== "") {
        clearTimeout(this.apiCall);
        this.apiCall = setTimeout(() => {
          this.apiKeywordSearch();
          this.$emit("searchKeyword", {
            searchState: true,
            keyword: this.keyword
          });
        }, 400);
        this.searchFilterTerms = [];
        this.selectedTaxonomy = {};
      }

      // clearTimeout(this.searchTimeout);
      // this.searchTimeout = setTimeout(() => {
      //   if (!this.searchLoading) {
      //     this.apiKeywordSearch();
      //     this.searchfilterTax();
      //   }
      // }, 500);
    },

    catFilter(slug, title) {
      // Filter the terms list by taxonomy
      if (this.keyword == "") {
        this.termList = this.taxonomyList.find(function(taxonomy) {
          return taxonomy.slug == slug;
        });
        // Pass the tax type to the parent
        this.selectedTaxonomy = { slug, title };
        // Clear the term list
        // this.selectedTerm = [];

        this.$emit("selectedRecordList", {
          searchState: true,
          taxType: this.selectedTaxonomy,
          terms: this.termList.term
        });
      }
    },

    termFilter(id, slug, title, recordCount) {
      const found = this.selectedTerm.some(term => term.title === title);
      if (!found) {
        this.selectedTerm.push({ id, slug, title, recordCount });
      } else {
        // Remove the slug from the selected term array
        this.selectedTerm.splice(
          this.selectedTerm.findIndex(term => term.slug == slug),
          1
        );
      }

      // get all the record by taxonomy if didn't select the term
      if (this.selectedTerm.length == 0) {
        this.catFilter(this.selectedTaxonomy.slug, this.selectedTaxonomy.title);
        return;
      }

      // Send the term slug, title, record list to parent
      this.$emit("searchChange", {
        searchState: true,
        selectedTerm: this.selectedTerm
      });
    },

    getRecordByTerm(tax, item) {
      this.$emit("selectedSearchTerm", {
        searchState: true,
        keyword: this.keyword,
        tax: {
          title_en: tax.title_en,
          title_tc: tax.title_tc,
          slug: tax.slug
        },
        term: {
          title_en: item.title_en,
          title_tc: item.title_tc,
          slug: item.slug
        }
      });

      if (
        this.searchFilterTerms.findIndex(term => term.slug === item.slug) === -1
      ) {
        this.searchFilterTerms.push({
          title_en: item.title_en,
          title_tc: item.title_tc,
          slug: item.slug
        });
      }
    },

    removeTerm(item) {
      let removeIndex = this.searchFilterTerms.findIndex(
        term => term.slug === item.slug
      );

      if (removeIndex > -1) {
        this.searchFilterTerms.splice(removeIndex, 1);
      }

      this.$emit("removeSelectedSearchTerm", {
        keyword: this.keyword,
        term: item
      });
    },

    catkeyWordFilter(item) {
      if (this.keyword !== "") {
        this.searchFilterTerms = [];
        this.selectedTaxonomy = { slug: item.slug, title_en: item.title_en };
        this.$emit("selectedSearchTaxKeyword", {
          searchState: true,
          keyword: this.keyword,
          tax: this.selectedTaxonomy
        });
      }
    },

    getTermClass(slug) {
      const found = this.selectedTerm.some(term => term.slug === slug);
      if (found) {
        return "active";
      }
    },

    async apiData() {
      // GET request using fetch with async/await
      const response = await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/taxonomy/?term=1`,
        {
          method: "GET",
          mode: "cors",
          cache: "default"
        }
      );
      const data = await response.json();

      this.taxonomyList = data;
    },

    async apiKeywordSearch(page = 1) {
      this.searchLoading = true;

      // this.apiCall = true;
      const response = await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/taxonomy/search?searchKey=${this.keyword}`,
        {
          method: "GET",
          mode: "cors",
          cache: "default"
        }
      );

      const data = await response.json();
      this.searchLoading = false;
      this.searchFilterTaxTermsCount = data;
      this.termList = [];
      // this.apiCall = false;

      // const response = await fetch(
      //   `/api/records/keyword?collection=1&term=1&meta=1&searchKey=${this.keyword}&page=${page}`,
      //   {
      //     method: "GET",
      //     mode: "cors",
      //     cache: "default"
      //   }
      // );
      // const data = await response.json();
      // this.searchRecordList = data.data;
      // this.searchLoading = false;
      // this.termList = [];
      // this.searchfilterTax();
      // this.$emit("searchKeyword", {
      //   searchState: true,
      //   records: this.searchRecordList
      // });
    },

    searchfilterTax() {
      const tempFilterTerms = this.searchRecordList.flatMap(function(item) {
        return item.term;
      });
      let filterTerms = [];
      tempFilterTerms.forEach(function(filterTerm, index) {
        // Find ducplicated term in the array
        const found = filterTerms.findIndex(term => term.id === filterTerm.id);
        // If found the term, count + 1

        if (found > -1) {
          filterTerms[found].count++;
        } else {
          //  If not found set the inital count 1 for the term
          filterTerm.count = 1;

          // For the color
          // if (filterTerm.taxonomy_id == null) {
          //   filterTerm.title_en = filterTerm.slug;
          // }

          // Push the term to the final array
          filterTerms.push(filterTerm);
        }
      });

      this.searchFilterTerms = filterTerms;

      // Taxonomy
      let searchFilterTaxonomyList = filterTerms.flatMap(function(term) {
        return term.taxonomy;
      });
      let taxList = searchFilterTaxonomyList.reduce(function(pre, cur) {
        var object = pre.filter(object => object.id === cur.id);
        if (object.length == 0) {
          pre.push(cur);
        }
        return pre;
      }, []);

      this.searchFilterTaxList = taxList;
    },

    getTermRecordCount(termid, selected = []) {
      if (selected.length == 0 || selected.some(i => i.id == termid)) {
        let findArray = this.getRecordwithTerm.map(function(item) {
          return item.term;
        });

        let filterSelected = findArray.filter(function(obj) {
          let checkExist = obj.some(i => {
            if (i.id == termid) {
              return true;
            }
          });
          if (checkExist) {
            return obj;
          }
        });

        return filterSelected.length;
      } else {
        let findArray = this.getRecordwithTerm.map(function(item) {
          return item.term;
        });

        let selectedIDs = selected.map(i => i.id);

        let filterSelected = findArray.filter(function(obj) {
          let checkExist2 = obj.some(i => {
            if (selectedIDs.includes(i.id)) {
              return true;
            }
          });
          if (!checkExist2) {
            return obj;
          }
        });

        if (filterSelected) {
          let getfilterSelected = filterSelected.filter(function(obj) {
            let checkExist3 = obj.some(i => {
              if (i.id == termid) {
                return true;
              }
            });
            if (checkExist3) {
              return obj;
            }
          });
          return getfilterSelected.length;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  padding: 0 20px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid $color-black;
  @include respond-to(screen-sm) {
    padding: 0 40px;
  }

  &--wrap {
    margin: 20px 0px;
    @include respond-to(screen-sm) {
      margin: 40px 0 40px;
    }
    .main-category {
      margin-top: 20px;
    }
  }

  &__title {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    &__form {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .search__input {
      @include transition(all 300ms ease-in-out);
      width: 80px;
      @include typo(bigTit);
      @include respond-to(screen-sm) {
        width: 190px;
      }
      &::placeholder {
        @include typo(bigTit);
        margin-right: 15px;
        color: $color-black !important;
      }
      &:focus,
      &:active {
        width: calc(100% - 20px);
        @include respond-to(screen-sm) {
          width: calc(100% - 30px);
        }
      }
    }
  }
  &__button {
    font-size: 18px;
    margin-bottom: 5px;
    @include respond-to(screen-sm) {
      font-size: 28px;
    }
  }

  &__filter {
    display: flex;
    font-family: "Graphik Compact", sans-serif;
    font-size: 22px;
    align-items: baseline;
    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;

      margin-bottom: 15px;
      @include respond-to(screen-md) {
        margin-bottom: 30px;
      }
      &.selected {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    &__taxonomy {
      color: $color-grey;
      flex-basis: 200px;
    }
    &__term {
      margin-right: 34px;

      button {
        font-size: 16px;
        @include respond-to(screen-md) {
          font-size: 22px;
        }
        &:after {
          content: attr(data-count);
          // display: inline-block ;
          vertical-align: super;
          font-size: 12px;
          padding-left: 2px;
          margin-top: 2px;
          @include respond-to(screen-sm) {
            font-size: 14px;
          }
        }
      }
    }
    p {
      width: 200px;
      font-size: 16px;
      @include respond-to(screen-md) {
        font-size: 22px;
      }
    }
  }
}

.category {
  &__list {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include respond-to(screen-md) {
      margin-bottom: 5px;
    }
    button {
      @include typo(standardlbl);
      font-weight: 400;
      line-height: 1;
      @include transition(all 300ms ease-in-out);
      &:hover {
        @include opacity(0.6);
      }
      span {
        @include transition(all 300ms ease-in-out);
      }
    }

    &:after {
      content: "";
      display: block;
      margin: 0 10px;
      vertical-align: middle;
      width: 1px;
      height: 15px;
      background-color: $color-black;
      @include respond-to(screen-sm) {
        width: 1px;
        height: 18px;
      }
    }
    &.shy {
      button span {
        opacity: 0.3;
      }
      &:hover {
        opacity: 1;
      }
    }
    // &.active-hover{
    // 	button span{
    // 		opacity: 1 !important;
    // 	}
    // }
  }
  &__item:last-of-type {
    &:after {
      display: none;
    }
  }
  &__sub {
    margin: 20px 0;
    @include respond-to(screen-sm) {
      margin: 20px 0;
    }
    .category__item {
      @include transition(all 300ms ease-in-out);
      &:after {
        height: 22px;
        margin: 0 15px;
      }
      &.active {
        button span {
          text-decoration: underline;
        }
        button:after {
          text-decoration: none;
        }
      }
      button {
        font-size: 18px;
        @include respond-to(screen-sm) {
          font-size: 22px;
        }
      }
      button {
        &:after {
          content: attr(data-count);
          // display: inline-block ;
          vertical-align: top;
          font-size: 14px;
          padding-left: 2px;
          margin-top: 2px;
        }
      }
    }
  }
}

.search-result {
  .category__item:after {
    display: none;
  }
  &--lbl {
    font-size: 16px;
    color: #848484;
    margin-bottom: 20px;
    @include respond-to(screen-sm) {
      font-size: 22px;
    }
  }
  &__item {
    margin-right: 25px;
    @include respond-to(screen-sm) {
      margin-right: 40px;
    }
    span {
      font-size: 16px;
      @include respond-to(screen-sm) {
        font-size: 22px;
      }
    }
    button {
      &:after {
        content: attr(data-count);
        // display: inline-block ;
        vertical-align: super;
        font-size: 13px;
        padding-left: 2px;
        margin-top: 2px;
        @include respond-to(screen-sm) {
          font-size: 14px;
        }
      }
    }
  }
  &--content {
    display: flex;
    flex-flow: wrap;
  }
}

.colorHex {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
