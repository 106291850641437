<template lang="html">
  <div>
    <searchFilter
      @searchChange="handleSearchChange"
      @selectedRecordList="handleSelectedRecordList"
      @selectedSearchTerm="handleSelectedSearchTerm"
      @selectedSearchTaxKeyword="handleSelectedSearchTaxKeyword"
      @removeSelectedSearchTerm="handleRemoveSelectedSearchTerm"
      @searchKeyword="handleSearchKeyword"
      :searchState="inSearch"
      ref="searchComponent"
    />
    <!-- Result masonry -->

    <div class="result__masonry" v-show="inSearch && !inKeywordSearch">
      <div class="page inner-container result">
        <div class="page-wrap">
          <div class="result--content">
            <div class="row">
              <div class="col-12 col-lg-4">
                <div class="result--itemWrap">
                  <p v-if="!this.inTaxonomySearch">
                    {{ resultCount + " " + $t("home.home_result_of") }}
                    {{
                      searchArray.title
                        ? searchArray.title.join(", ")
                        : "" | capitalize
                    }}
                  </p>

                  <p v-else>
                    {{ resultCount + " " + $t("home.home_result_of") }} "{{
                      this.taxonomyType.title
                    }}"
                  </p>

                  <div v-if="toShowOnHover">
                    <recordDescription
                      :record="recordShowItem"
                    ></recordDescription>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-8 ">
                <div class="contact--itemWrap ">
                  <div
                    v-masonry=""
                    transition-duration="0"
                    item-selector=".item"
                    column-width=".item__sizer"
                    percent-position="true"
                    gutter=".item__gutter"
                    id="resultMasonry"
                  >
                    <div class="item__sizer"></div>
                    <div class="item__gutter"></div>

                    <div
                      v-masonry-tile
                      v-for="(item, index) in resultList"
                      :key="index"
                    >
                      <router-link
                        :to="{
                          name: 'Record Details',
                          params: { slug: item.slug }
                        }"
                      >
                        <SingleItem
                          :name="item.title_en"
                          :imageName="item.gallery[0].image"
                          :sizeType="item.sizeType"
                          :hoverFunction="true"
                          :relatedItem="false"
                          @updateShowRecord="updatedRecordShowItem(item)"
                          @updateCursor="moveCursor"
                          :shy="toShowOnHover"
                        ></SingleItem
                      ></router-link>
                    </div>
                  </div>
                  <!-- <ul>
										<li v-for="(item, index) in resultList" :key="index">
											{{ item.title_en }}
										</li>
									</ul> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="custom__cursor"
        :style="{ top: cursorPos.y + 'px', left: cursorPos.x + 'px' }"
        v-show="cursorShow"
      >
        {{ $t("home.home_discover_more") }}
      </div>
    </div>
    <!-- End result masonry -->

    <!-- Home masonry -->

    <div
      class="item__masonry"
      v-show="(!inSearch && imgOnloadCompleted) || inKeywordSearch"
    >
      <div
        v-masonry=""
        transition-duration="0"
        item-selector=".item"
        column-width=".item__sizer"
        percent-position="true"
        gutter=".item__gutter"
        id="itemMasonry"
      >
        <div class="item__sizer"></div>
        <div class="item__gutter"></div>

        <!-- All Colelction -->
        <div
          v-masonry-tile
          v-for="(collection, index) in homeCollection"
          :key="collection.slug"
        >
          <router-link
            :to="{
              name: 'Collection Details',
              params: { slug: collection.slug }
            }"
          >
            <CollectionItem
              v-if="!inKeywordSearch"
              :name="lang == 'en' ? collection.title_en : collection.title_tc"
              :imageName="collection.records[0].gallery[0].image"
              :description="
                lang == 'en'
                  ? collection.description_en
                  : collection.description_tc
              "
            ></CollectionItem>
          </router-link>
        </div>
        <div
          v-masonry-tile
          v-for="(highlight, index) in homeHighlight"
          :key="highlight.record.slug"
        >
          <router-link
            :to="{
              name: 'Record Details',
              params: { slug: highlight.record.slug }
            }"
          >
            <HighlightItem
              :name="
                lang == 'en'
                  ? highlight.record.title_en
                  : highlight.record.title_tc
              "
              :description="
                lang == 'en'
                  ? highlight.record.description_en
                  : highlight.record.description_tc
              "
              :imageName="highlight.record.gallery[0].image"
              :sizeType="highlight.record.sizeType"
              :hoverFunction="false"
              :color="highlight.color"
              v-if="!inKeywordSearch"
            ></HighlightItem>
          </router-link>
        </div>
        <div
          v-masonry-tile
          v-for="(record, index) in homeRecord.recordList"
          :key="record.slug + index"
        >
          <router-link
            :to="{
              name: 'Record Details',
              params: { slug: record.slug }
            }"
          >
            <SingleItem
              :name="lang == 'en' ? record.title_en : record.title_tc"
              :imageName="record.gallery[0].image"
              :sizeType="record.sizeType"
              :hoverFunction="false"
              :hoverColor="homeRecord.hoverColor"
            ></SingleItem>
          </router-link>
        </div>
      </div>
    </div>
    <!-- End home masonry -->
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { mapState, mapActions, mapGetters } from "vuex";
gsap.registerPlugin(ScrollTrigger);
import SearchFilter from "@/components/common/SearchFilter";
import SingleItem from "@/components/masonry/SingleItem";
import CollectionItem from "@/components/masonry/CollectionItem";
import HighlightItem from "@/components/masonry/HighlightItem";
import recordDescription from "@/views/Home/parts/recordDescription";
export default {
  name: "home",
  components: {
    SearchFilter,
    SingleItem,
    CollectionItem,
    HighlightItem,
    recordDescription
  },

  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      loading: true,
      data: null,
      collectionItems: [],
      inSearch: false,
      inKeywordSearch: false,
      inTaxonomySearch: false,
      searchTermList: [],
      searchTax: "",
      taxonomyType: {},
      searchArray: { title: [], slug: [] },
      resultCount: null,
      resultList: [],
      toShowOnHover: false,
      recordShowItem: null,
      cursorPos: { x: 0, y: 0 },
      cursorShow: false,
      busy: false,
      page: 1,
      lastPage: 1,
      apiCall: false,
      keyword: "",
      dataLoading: false,
      homeRecord: [],
      homeCollection: [],
      homeHighlight: []
    };
  },

  mounted() {
    this.getMoreResult();
    this.createScrollTrigger();
  },

  beforeMount() {
    this.s3RootPath = this.s3Path;
    // this.apiHome();
    this.apiHome();
    setTimeout(() => {
      this.$redrawVueMasonry();
    }, 500);
  },

  computed: {
    imgOnloadCompleted() {
      if (!this.collectionItems) {
        return false;
      }
      let res = true;
      this.collectionItems.forEach(item => {
        if (!item.sizeType) {
          res = false;
        }
      });
      return res;
    }
  },

  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  watch: {
    collectionItems: function(newItems, oldItems) {
      console.log("collection item change");
      setTimeout(() => {
        this.$redrawVueMasonry();
      }, 300);
    },
    keyword: function(newString, oldString) {
      if (newString == "") {
        this.inSearch = false;
        this.inKeywordSearch = false;
        this.apiHome();
        setTimeout(() => {
          this.$redrawVueMasonry();
        }, 300);
      }
    }
  },

  created() {},

  methods: {
    createScrollTrigger() {
      let _this = this;
      ScrollTrigger.matchMedia({
        // all
        all: function() {
          ScrollTrigger.create({
            id: "dataList",
            trigger: document.querySelector(".footer"),
            start: () => "-=500 50%",
            // end: () => "+=10",
            end: () => "+=200",
            // markers: true,
            onRefresh: ({ progress, direction, isActive }) => {
              if (isActive) {
                _this.getMoreResult();
              }
            },
            onEnter: self => {
              console.log("onEnter");
              _this.getMoreResult();
            }
          });
        }
      });
    },
    getMoreResult() {
      if (
        this.inKeywordSearch &&
        this.keyword &&
        this.page !== this.lastPage &&
        this.searchTermList.length > 0 &&
        !this.dataLoading
      ) {
        this.dataLoading = true;
        this.page += 1;
        this.apiKeywordGetItem(
          this.keyword,
          this.searchTermList,
          null,
          this.page
        );
        this.dataLoading = false;
        setTimeout(() => {
          // this.$redrawVueMasonry();
          ScrollTrigger.refresh();
        }, 400);
      } else if (
        this.inKeywordSearch &&
        this.keyword &&
        this.inTaxonomySearch &&
        !this.dataLoading
      ) {
        this.dataLoading = true;
        this.page += 1;
        this.apiKeywordGetItem(this.keyword, null, this.searchTax, this.page);
        this.dataLoading = false;
        setTimeout(() => {
          this.$redrawVueMasonry();
          ScrollTrigger.refresh();
        }, 400);
      } else if (this.inKeywordSearch && this.keyword && !this.dataLoading) {
        this.page += 1;
        this.dataLoading = true;
        this.apiKeywordGetItem(this.keyword, null, null, this.page);
        this.dataLoading = false;
        setTimeout(() => {
          // this.$redrawVueMasonry();
          ScrollTrigger.refresh();
        }, 400);
      } else if (
        this.inKeywordSearch &&
        this.page !== this.lastPage &&
        !this.dataLoading
      ) {
        this.page += 1;
        this.$refs.searchComponent.apiKeywordSearch(this.page);
        this.dataLoading = true;
        setTimeout(() => {
          // this.$redrawVueMasonry();
          ScrollTrigger.refresh();
        }, 400);
        this.dataLoading = false;
      } else if (
        this.inSearch &&
        this.page !== this.lastPage &&
        !this.dataLoading
      ) {
        this.dataLoading = true;
        console.log(this.page);
        console.log(this.searchArray.slug);
        this.page += 1;
        let recordList =
          this.searchArray.slug && this.searchArray.slug.length != 0
            ? this.apiGetRecordList(
                null,
                this.searchArray.slug.join(","),
                this.page
              )
            : this.apiGetRecordList(this.taxonomyType.slug, null, this.page);

        recordList.then(list => {
          console.log(list);
          this.resultList.push(...list.data);
          this.dataLoading = false;

          setTimeout(() => {
            // this.$redrawVueMasonry();
            ScrollTrigger.refresh();
          }, 400);
        });
      }
    },

    handleSelectedRecordList(e) {
      this.page = 1;
      this.inTaxonomySearch = e.searchState;
      this.inKeywordSearch = !e.searchState;
      this.inSearch = e.searchState;
      this.taxonomyType = e.taxType;
      this.searchArray = {};

      // get the total of the all records list
      // this.resultCount = e.terms
      //   .map(term => term.record_count)
      //   .reduce((totalCount, recordLength) => totalCount + recordLength);

      // extract the all record list from the terms
      let recordList = this.apiGetRecordList(this.taxonomyType.slug);

      recordList.then(list => {
        this.resultList = list.data;
        this.resultCount = list.total;
        setTimeout(() => {
          // this.$redrawVueMasonry();
          ScrollTrigger.refresh();
        }, 500);
      });
    },

    handleSearchChange(e) {
      this.page = 1;
      this.inTaxonomySearch = false;

      this.searchArray.title = e.selectedTerm.map(term => `\"${term.title}\"`);
      this.searchArray.slug = e.selectedTerm.map(term => term.slug);
      // this.resultCount = e.selectedTerm.reduce(
      //   (total, term) => total + term.recordCount,
      //   0
      // );

      let recordList = this.apiGetRecordList(
        null,
        this.searchArray.slug.join(",")
      );

      recordList.then(list => {
        this.resultList = list.data;
        this.resultCount = list.total;
        setTimeout(() => {
          this.$redrawVueMasonry();
        }, 950);
      });

      // this.$redrawVueMasonry();
    },

    handleSearchKeyword(e) {
      this.page = 1;
      this.searchTermList = [];
      this.searchTaxList = [];
      this.resultList = [];
      this.resultCount = 0;
      this.inKeywordSearch = e.searchState;
      this.inSearch = e.searchState;
      this.inTaxonomySearch = false;
      this.taxonomyType = {};
      this.keyword = e.keyword;
      this.searchArray = {};
      this.apiKeywordGetItem(e.keyword, null, null, this.page);
      setTimeout(() => {
        this.$redrawVueMasonry();
        ScrollTrigger.refresh();
      }, 500);
    },

    handleSelectedSearchTerm(e) {
      this.page = 1;
      if (this.searchTermList.indexOf(e.term.slug) === -1) {
        this.searchTermList.push(e.term.slug);
      }

      if (this.searchTaxList.indexOf(e.tax.slug) === -1) {
        this.searchTaxList.push(e.tax.slug);
      }

      this.inKeywordSearch = e.searchState;
      this.inSearch = !e.searchState;
      this.inTaxonomySearch = !e.searchState;
      this.apiKeywordGetItem(e.keyword, this.searchTermList, null, this.page);
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 500);
    },

    handleRemoveSelectedSearchTerm(e) {
      this.page = 1;
      if (this.searchTermList.length > 0) {
        let removeIndex = this.searchTermList.indexOf(e.term.slug);
        if (removeIndex > -1) {
          this.searchTermList.splice(removeIndex, 1);
        }
        if (this.searchTermList.length == 0) {
          this.apiKeywordGetItem(e.keyword, null, null, this.page);
        } else {
          this.apiKeywordGetItem(
            e.keyword,
            this.searchTermList,
            null,
            this.page
          );
        }
      }
    },

    handleSelectedSearchTaxKeyword(e) {
      this.page = 1;
      this.searchTermList = [];
      this.inKeywordSearch = e.searchState;
      this.inSearch = e.searchState;
      this.inTaxonomySearch = e.searchState;
      this.searchTax = e.tax.slug;
      this.apiKeywordGetItem(e.keyword, null, e.tax.slug, this.page);
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 500);
    },

    async apiRecordData() {
      // GET request using fetch with async/await
      const response = await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/taxonomy/search?tax=${
          this.taxonomyType.slug
        }&term=${this.searchArray.slug.join(",")}`,
        {
          method: "GET",
          mode: "cors",
          cache: "default"
        }
      );
      const data = await response.json();
    },

    randomClass() {
      return "item__single__" + Math.floor(Math.random() * (2 - 1 + 1) + 1);
    },

    async apiGetRecordList(tax, term, page = 1) {
      console.log(page);
      let url = tax
        ? `${process.env.VUE_APP_BASE_API_URL}/taxonomy/search?tax=${tax}&page=${page}`
        : `${process.env.VUE_APP_BASE_API_URL}/taxonomy/search?term=${term}&page=${page}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "default"
      });
      const data = await response.json();
      this.lastPage = data.last_page;
      return data;
    },

    async apiData() {
      console.log("api Call");
      // GET request using fetch with async/await
      const response = await fetch(
        `${process.env.VUE_APP_BASE_API_URL}/record/`,
        {
          method: "GET",
          mode: "cors",
          cache: "default"
        }
      );
      const data = await response.json();
      // this.data = data.module[1].collection;
      this.data = data;
      // this.collectionItems = this.data.items;

      this.collectionItems = this.data;

      // for (var i of this.data) {
      //   this.collectionItems.push(i);
      // }

      this.collectionItems.forEach((item, i) => {
        let itemImg = new Image();
        let tmpItem = item;

        itemImg.src =
          `${this.s3RootPath}${item.gallery[0].image}` |
          imageResizedPath("medium");
        itemImg.onload = () => {
          // console.log("Image onload" + itemImg.src);

          // console.log(
          //   `the image dimensions are ${itemImg.width}x${itemImg.height}`
          // );
          if (itemImg.width > itemImg.height) {
            tmpItem.sizeType = "landscape";
          } else if (itemImg.height > itemImg.width) {
            tmpItem.sizeType = "portrait";
          } else {
            tmpItem.sizeType = "square";
          }

          this.$set(this.collectionItems, i, tmpItem);
        };
      });
    },

    async apiHome() {
      const response = await fetch(
        // `http://uat.graphicarchive.hk/api/main-home`,
        `${this.WP_API_URL}api/main-home`,

        {
          method: "GET",
          mode: "cors",
          cache: "default"
        }
      );
      const data = await response.json();

      const recordData = data.module[0];
      const collectionData = data.module[1];
      const hightlightData = data.module[2];
      this.homeRecord = recordData.content;
      this.homeCollection = collectionData.content.collectionList;
      this.homeHighlight = hightlightData.content.recordList;
    },

    async apiKeywordItem(keyword, term, tax, page = 1) {
      let termList = [...new Set(term)].join();
      let taxList = Array.isArray(tax) ? [...new Set(tax)].join() : tax;

      let url = term
        ? `${process.env.VUE_APP_BASE_API_URL}/taxonomy/search?searchKey=${keyword}&tax=${taxList}&term=${termList}&page=${page}`
        : `${process.env.VUE_APP_BASE_API_URL}/taxonomy/search?searchKey=${keyword}&tax=${taxList}&page=${page}`;
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "default"
      });
      const data = await response.json();
      this.lastPage = data.last_page;

      let tempCollectionList = [];

      if (termList) {
        data.forEach(item => {
          let collectionList = item.term.flatMap(termItem => termItem.record);
          tempCollectionList.push(...collectionList);
        });
      } else {
        tempCollectionList = data[0].term.flatMap(termItem => termItem.record);
      }

      this.collectionItems = tempCollectionList.reduce(function(pre, cur) {
        var object = pre.filter(object => object.id === cur.id);
        if (object.length == 0) {
          pre.push(cur);
        }
        return pre;
      }, []);
    },

    async apiKeywordGetItem(keyword, term = null, tax = null, page = 1) {
      if (!this.apiCall) {
        let termList = [...new Set(term)].join();

        let url = "";

        url = `${process.env.VUE_APP_BASE_API_URL}/records/keyword?searchKey=${keyword}&page=${page}`;
        if (term) {
          url = `${process.env.VUE_APP_BASE_API_URL}/records/keyword?searchKey=${keyword}&termList=${termList}&page=${page}`;
        }
        if (tax) {
          url = `${process.env.VUE_APP_BASE_API_URL}/records/keyword?searchKey=${keyword}&tax=${tax}&page=${page}`;
        }

        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          cache: "default"
        });
        const data = await response.json();
        this.lastPage = data.last_page;

        if (page == 1) {
          // this.collectionItems = data.data;
          this.homeRecord.recordList = data.data;
        } else {
          // this.collectionItems = data.data;
          this.homeRecord.recordList.push(...data.data);
        }
        this.apiCall = false;
      }
    },

    updatedRecordShowItem(item) {
      this.cursorShow = !this.cursorShow;
      this.toShowOnHover = true;
      this.recordShowItem = item;
    },

    moveCursor(e) {
      this.cursorPos.x = e.clientX + 16;
      this.cursorPos.y = e.clientY - 8;
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  // padding: 0 10px;
  &__masonry {
    padding: 0 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    @include respond-to(screen-md) {
      padding: 0 40px;
      // margin: 50px -10px;
    }
  }
  &__sizer {
    width: 48%;
    // width:calc(47% + 10px);
    // width: 224px;
    @include respond-to(screen-md) {
      // width: 15%;
      // width: 224px;
      width: 23.125%;
    }
    @include respond-to(screen-xl) {
      width: 15%;
      // width: 224px;
    }
  }
  &__gutter {
    width: 3.5%;
    @include respond-to(screen-md) {
      width: 2.5%;
      // width: 224px;
    }
    @include respond-to(screen-xl) {
      width: 2%;
      // width: 224px;
    }
  }
}

.custom__cursor {
  position: fixed;
  z-index: 2;
}

.result--itemWrap {
  padding-right: 0px;
  @include respond-to(screen-md) {
    padding-right: 40px;
  }
  p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  h3 {
    @include fontfamily(GraCompact);
    font-size: 25px;
  }
}
.contact--itemWrap {
  height: 100%;
}
.result--content {
  .item__single__wrapper,
  a {
    cursor: crosshair;
  }
}

.tax {
  &--list {
    margin-bottom: 100px;
  }
  &--title {
    font-size: 18px;
    font-weight: 400px;
  }
}
</style>
