<template lang="html">
  <!-- single item markup -->
  <div class="item item__highlight" :class="imgSizeClass">
    <!-- single item wrapper -->
    <div class="item__highlight__wrapper">
      <img
        :src="`${s3RootPath}${imageName}` | imageResizedPath('large')"
        class="item__cover"
        alt=""
      />

      <!-- single item overlay wrapper -->
      <div v-show="!hoverFunction" class="item__overlay__wrapper">
        <div
          class="item__overlay"
          :style="{ background: overlayBackgroundColor }"
        >
          <h4>Highlight</h4>
          <h2>
            {{ name }}
          </h2>
          <p>
            {{ descriptionPlaceholder }}
          </p>
        </div>
      </div>
      <!-- End single item overlay wrapper -->
    </div>
    <!--end single item wrapper -->
  </div>
  <!-- end single item markup -->
</template>

<script>
import { convertHex } from "@/utils/common";

export default {
  name: "HighlightItem",
  props: {
    name: String,
    color: String,
    imageName: String,
    sizeType: String,
    hoverFunction: Boolean,
    description: String
  },
  beforeMount() {
    this.s3RootPath = this.s3Path;
  },

  data() {
    return {
      opacity: 85
    };
  },
  computed: {
    imgSizeClass: function() {
      return {
        portrait: this.sizeType == "portrait"
      };
    },
    overlayBackgroundColor: function() {
      return convertHex(this.color, this.opacity);
    },
    descriptionPlaceholder() {
      if (!this.description) {
        return "";
      } else {
        return this.description.slice(0, 150) + " ...";
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.item {
  &__cover {
    width: 100%;
    height: 100%;
  }
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    font-size: 36px;
    color: $color-white;
    background: rgba(201, 60, 27, 0.85);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include respond-to(screen-sm) {
      padding: 15px;
    }
    &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      @include transition(all 500ms ease-in-out);
      // opacity: 1;
      color: white;
      @include fontfamily(GraCompact);

      p {
        z-index: 1;
        line-height: 1.2;
        font-size: 16px;
        display: none;
        @include respond-to(screen-sm) {
          font-size: 16px;
          display: none;
        }
        @include respond-to(screen-md) {
          font-size: 18px;
          display: block;
        }
      }
      h4 {
        font-size: 15px;
        z-index: 1.2;
        font-weight: 600;
        display: none;
        @include respond-to(screen-sm) {
          display: block;
          z-index: 1;
        }
      }
      h2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.2;
        @include respond-to(screen-md) {
          font-size: 38px;
          z-index: 1;
          line-height: 1;
        }
      }
    }
  }
  &__highlight {
    overflow: hidden;
    // cursor: crosshair;
    // width: 46%;
    width: 48.5%;
    margin-bottom: 5%;
    @include respond-to(screen-md) {
      width: 23.125%;
      // width: 25.5%;
      // height: 300px;
      margin-bottom: 2.5%;
    }
    @include respond-to(screen-xl) {
      width: 15%;
      margin-bottom: 2%;
    }
    // &:hover .item__overlay__wrapper {
    //   opacity: 1;
    // }
    img {
      object-fit: cover;
    }
    &__wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    &.portrait {
      // width: 46%;
      width: 48%;
      @include respond-to(screen-md) {
        width: 23.125%;
        // width: 20%;
        height: auto;
      }
      @include respond-to(screen-xl) {
        width: 15%;
      }
      .item__overlay {
        p {
          display: block;
        }
      }
      h2 {
        font-size: 20px;
        @include respond-to(screen-md) {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
