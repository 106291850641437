var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('searchFilter',{ref:"searchComponent",attrs:{"searchState":_vm.inSearch},on:{"searchChange":_vm.handleSearchChange,"selectedRecordList":_vm.handleSelectedRecordList,"selectedSearchTerm":_vm.handleSelectedSearchTerm,"selectedSearchTaxKeyword":_vm.handleSelectedSearchTaxKeyword,"removeSelectedSearchTerm":_vm.handleRemoveSelectedSearchTerm,"searchKeyword":_vm.handleSearchKeyword}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.inSearch && !_vm.inKeywordSearch),expression:"inSearch && !inKeywordSearch"}],staticClass:"result__masonry"},[_c('div',{staticClass:"page inner-container result"},[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"result--content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-4"},[_c('div',{staticClass:"result--itemWrap"},[(!this.inTaxonomySearch)?_c('p',[_vm._v(" "+_vm._s(_vm.resultCount + " " + _vm.$t("home.home_result_of"))+" "+_vm._s(_vm._f("capitalize")(_vm.searchArray.title ? _vm.searchArray.title.join(", ") : ""))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.resultCount + " " + _vm.$t("home.home_result_of"))+" \""+_vm._s(this.taxonomyType.title)+"\" ")]),(_vm.toShowOnHover)?_c('div',[_c('recordDescription',{attrs:{"record":_vm.recordShowItem}})],1):_vm._e()])]),_c('div',{staticClass:"col-12 col-lg-8 "},[_c('div',{staticClass:"contact--itemWrap "},[_c('div',{directives:[{name:"masonry",rawName:"v-masonry"}],attrs:{"transition-duration":"0","item-selector":".item","column-width":".item__sizer","percent-position":"true","gutter":".item__gutter","id":"resultMasonry"}},[_c('div',{staticClass:"item__sizer"}),_c('div',{staticClass:"item__gutter"}),_vm._l((_vm.resultList),function(item,index){return _c('div',{directives:[{name:"masonry-tile",rawName:"v-masonry-tile"}],key:index},[_c('router-link',{attrs:{"to":{
                          name: 'Record Details',
                          params: { slug: item.slug }
                        }}},[_c('SingleItem',{attrs:{"name":item.title_en,"imageName":item.gallery[0].image,"sizeType":item.sizeType,"hoverFunction":true,"relatedItem":false,"shy":_vm.toShowOnHover},on:{"updateShowRecord":function($event){return _vm.updatedRecordShowItem(item)},"updateCursor":_vm.moveCursor}})],1)],1)})],2)])])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cursorShow),expression:"cursorShow"}],staticClass:"custom__cursor",style:({ top: _vm.cursorPos.y + 'px', left: _vm.cursorPos.x + 'px' })},[_vm._v(" "+_vm._s(_vm.$t("home.home_discover_more"))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:((!_vm.inSearch && _vm.imgOnloadCompleted) || _vm.inKeywordSearch),expression:"(!inSearch && imgOnloadCompleted) || inKeywordSearch"}],staticClass:"item__masonry"},[_c('div',{directives:[{name:"masonry",rawName:"v-masonry"}],attrs:{"transition-duration":"0","item-selector":".item","column-width":".item__sizer","percent-position":"true","gutter":".item__gutter","id":"itemMasonry"}},[_c('div',{staticClass:"item__sizer"}),_c('div',{staticClass:"item__gutter"}),_vm._l((_vm.homeCollection),function(collection,index){return _c('div',{directives:[{name:"masonry-tile",rawName:"v-masonry-tile"}],key:collection.slug},[_c('router-link',{attrs:{"to":{
              name: 'Collection Details',
              params: { slug: collection.slug }
            }}},[(!_vm.inKeywordSearch)?_c('CollectionItem',{attrs:{"name":_vm.lang == 'en' ? collection.title_en : collection.title_tc,"imageName":collection.records[0].gallery[0].image,"description":_vm.lang == 'en'
                  ? collection.description_en
                  : collection.description_tc}}):_vm._e()],1)],1)}),_vm._l((_vm.homeHighlight),function(highlight,index){return _c('div',{directives:[{name:"masonry-tile",rawName:"v-masonry-tile"}],key:highlight.record.slug},[_c('router-link',{attrs:{"to":{
              name: 'Record Details',
              params: { slug: highlight.record.slug }
            }}},[(!_vm.inKeywordSearch)?_c('HighlightItem',{attrs:{"name":_vm.lang == 'en'
                  ? highlight.record.title_en
                  : highlight.record.title_tc,"description":_vm.lang == 'en'
                  ? highlight.record.description_en
                  : highlight.record.description_tc,"imageName":highlight.record.gallery[0].image,"sizeType":highlight.record.sizeType,"hoverFunction":false,"color":highlight.color}}):_vm._e()],1)],1)}),_vm._l((_vm.homeRecord.recordList),function(record,index){return _c('div',{directives:[{name:"masonry-tile",rawName:"v-masonry-tile"}],key:record.slug + index},[_c('router-link',{attrs:{"to":{
              name: 'Record Details',
              params: { slug: record.slug }
            }}},[_c('SingleItem',{attrs:{"name":_vm.lang == 'en' ? record.title_en : record.title_tc,"imageName":record.gallery[0].image,"sizeType":record.sizeType,"hoverFunction":false,"hoverColor":_vm.homeRecord.hoverColor}})],1)],1)})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }